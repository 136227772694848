import { Alert } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import React from "react";

interface Props {
  type: "error" | "success";
  message: string | React.ReactNode;
  description: string | React.ReactNode;
  margin?: string;
  closable?: boolean;
}

const AlertMessage = ({ type, message, description, margin, closable = false }: Props) => {
  return (
    <Alert
      className={`alert-mesage ${type}`}
      style={{ margin: margin }}
      message={<h4>{message}</h4>}
      description={<p>{description}</p>}
      type={type}
      showIcon
      closable={closable}
      icon={<CloseCircleOutlined />}
    />
  );
};

export default AlertMessage;
