import Modal from "antd/es/modal/Modal";
import { Card, Flex, notification, Space } from "antd";
import { ButtonDs } from "design-system";
import ConfirmModal from "../../../shared/component/ConfirmModal";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Form, FormDs, InputDs, SelectDs } from "design-system";
import { provincesOptions } from "../../../shared/service/province-list-option";
import { NotificationComponent } from "../../../shared/component/NotificationComponent";
import storeMasterService from "../services/store-master-service";
import { StoreType } from "../models/store";
import { AxiosError } from "axios";
import { HTTP_STATUS_CODE } from "../../../utils/Constants";

interface CreateStoreModalProps {
  open: boolean;
  onClose: () => void;
  onOk: (newStore: StoreType) => void;
}

export interface CreateStoreManagementFormValues {
  company: string;
  store_number: string;
  store_name: string;
  province: string;
  latlng: string;
  mobile_phone: string;
  remark: string;
}

enum CreateStoreModalState {
  CONFIRM_CREATE = "CONFIRM_CREATE",
  CANCEL_CREATE = "CANCEL_CREATE",
}

const CreateStoreModal = ({ open = true, onClose, onOk }: CreateStoreModalProps) => {
  const [openConfirmModalState, setOpenConfirmModalState] = useState<null | CreateStoreModalState>(null);
  const [form] = Form.useForm<CreateStoreManagementFormValues>();
  const [notificationComponent, contextHolderNoti] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const provinces = provincesOptions.slice(1);
  const companies = [
    {
      label: "CJ",
      value: "CJ",
    },
    {
      label: "CJx",
      value: "CJx",
    },
    {
      label: "TD",
      value: "TD",
    },
  ];

  const openNotification = (type: "success" | "error", topic = "", message = "") => {
    NotificationComponent({
      notification: notificationComponent,
      type,
      topic,
      message,
    });
  };

  const onConfirmCreate = () => {
    form
      .validateFields()
      .then(() => {
        setOpenConfirmModalState(CreateStoreModalState.CONFIRM_CREATE);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      storeMasterService.isloadingTable.next(true);
      const res = await storeMasterService.createStore(form.getFieldsValue());
      setOpenConfirmModalState(null);
      form.resetFields();
      onClose();
      onOk({ ...res, is_new_store: true });
      openNotification("success", "", "เพิ่มร้านค้าใหม่สําเร็จ");
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
      storeMasterService.isloadingTable.next(false);
    }
  };

  const handleError = (err: any) => {
    let message = "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง";
    if (err instanceof AxiosError) {
      message =
        err.response?.status === HTTP_STATUS_CODE.DUPLICATE ? "รหัสร้านค้านี้เคยลงทะเบียนเเล้ว" : message;
    }
    openNotification("error", "", message);
  };

  const trimValue = (value: string) => {
    return value.trim();
  };

  const formatPhoneNumber = (rawValue: string) => {
    const trimmedValue = rawValue.trim();
    const cleanedValue = trimmedValue.replace(/[\s+\D-]/g, "");

    return cleanedValue;
  };

  const phoneNumberValidator = (_: any, value: string) => {
    if (value) {
      if (value.length === 10) {
        return Promise.resolve();
      } else if (value.length !== 10) {
        return Promise.reject("");
      }
    } else {
      return Promise.reject("");
    }
  };

  return (
    <>
      <Modal
        title="เพิ่มร้านค้า"
        className="modal-create-store"
        centered
        open={open}
        width={950}
        onCancel={() => setOpenConfirmModalState(CreateStoreModalState.CANCEL_CREATE)}
        footer={
          <>
            <ButtonDs
              type="default"
              onClick={() => setOpenConfirmModalState(CreateStoreModalState.CANCEL_CREATE)}
            >
              ยกเลิก
            </ButtonDs>
            <ButtonDs htmlType="submit" type="primary" onClick={onConfirmCreate}>
              ยืนยัน
            </ButtonDs>
          </>
        }
      >
        <Card style={{ padding: "16px" }}>
          <h3>ข้อมูลทั่วไป</h3>
          <FormDs form={form} initialValues={{ remark: "" }} layout="vertical" requiredMark={true}>
            <Flex gap={16}>
              <Form.Item
                name="company"
                label="บริษัท"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <SelectDs placeholder="กรุณาเลือกบริษัท" options={companies} style={{ width: "330px" }} />
              </Form.Item>
            </Flex>
            <Flex gap={16}>
              <Form.Item
                name="store_number"
                label="รหัสร้านค้า"
                rules={[
                  {
                    required: true,
                  },
                  {
                    min: 4,
                    message: "รหัสร้านค้าต้องมีอย่างน้อย 4 ตัวอักษร",
                  },
                  {
                    pattern: new RegExp(/^[a-zA-Z0-9]+$/),
                    message: "รหัสร้านค้าต้องเป็นตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น",
                  },
                ]}
                normalize={trimValue}
              >
                <InputDs placeholder="กรุณากรอกรหัสร้านค้า" />
              </Form.Item>
              <Form.Item
                name="store_name"
                label="ชื่อร้านค้า"
                rules={[
                  {
                    required: true,
                  },
                ]}
                normalize={trimValue}
              >
                <InputDs placeholder="กรุณากรอกชื่อร้านค้า" />
              </Form.Item>
            </Flex>
            <Flex gap={16}>
              <Form.Item
                name="province"
                label="จังหวัด"
                rules={[
                  {
                    required: true,
                  },
                ]}
                required
              >
                <SelectDs
                  className="ant-select-lg ant-select-in-form-item select-ds"
                  placeholder="กรุณาเลือกจังหวัด"
                  style={{ width: "330px" }}
                  options={provinces}
                  showSearch
                />
              </Form.Item>
              <Form.Item
                name="latlng"
                label="พิกัดร้านค้า"
                rules={[
                  {
                    pattern: new RegExp(/^-?\d+(\.\d+)?,-?\d+(\.\d+)?$/),
                    message: "รูปแบบ lat,long ไม่ถูกต้อง",
                  },
                  {
                    required: true,
                  },
                ]}
                normalize={trimValue}
              >
                <InputDs placeholder="กรุณากรอก lat,long" />
              </Form.Item>
            </Flex>
            <Flex gap={16}>
              <Form.Item
                name="mobile_phone"
                label="เบอร์โทร"
                help="ระบุเบอร์โทร(ตัวเลข), ไม่มีเว้นวรรค ไม่มีอักขระพิเศษ"
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: phoneNumberValidator,
                  },
                ]}
                normalize={(value) => formatPhoneNumber(value)}
              >
                <InputDs placeholder="กรุณากรอกเบอร์โทร" />
              </Form.Item>
              <Form.Item name="remark" label="remark">
                <InputDs placeholder="กรุณากรอกหมายเหตุ" />
              </Form.Item>
            </Flex>
          </FormDs>
        </Card>
      </Modal>
      <ConfirmModal
        title={
          <Space>
            <QuestionCircleOutlined /> ยืนยันการเพิ่มร้านค้าใหม่
          </Space>
        }
        onClickConfirmCancel={() => {
          setOpenConfirmModalState(null);
        }}
        onClickConfirmOK={onSubmit}
        open={openConfirmModalState === CreateStoreModalState.CONFIRM_CREATE}
        loading={isLoading}
      >
        <Flex vertical>
          <p>{`ยืนยันการเพิ่มร้านค้าใหม่: ${form.getFieldValue("store_number")} (${form.getFieldValue(
            "store_name",
          )})`}</p>
          <p>{`บริษัท: ${form.getFieldValue("company")}`}</p>
          <p>{`จังหวัด: ${form.getFieldValue("province")}`}</p>
          <p>{`ตำแหน่งร้านค้า: ${form.getFieldValue("latlng")}`}</p>
          <p>{`เบอร์โทร: ${form.getFieldValue("mobile_phone")}`}</p>
          <p>{`Remark: ${form.getFieldValue("remark") ? form.getFieldValue("remark") : "-"}`}</p>
        </Flex>
      </ConfirmModal>
      <ConfirmModal
        title={
          <Space>
            <QuestionCircleOutlined /> ออกจากหน้านี้หรือไม่
          </Space>
        }
        onClickConfirmCancel={() => {
          setOpenConfirmModalState(null);
        }}
        onClickConfirmOK={() => {
          form.resetFields();
          setOpenConfirmModalState(null);
          onClose();
        }}
        open={openConfirmModalState === CreateStoreModalState.CANCEL_CREATE}
      >
        <p>หากออกจากหน้านี้ ข้อมูลทั้งหมดจะไม่ถูกบันทึก ต้องการออกจากหน้านี้หรือไม่ ?</p>
      </ConfirmModal>
      {contextHolderNoti}
    </>
  );
};

export default CreateStoreModal;
