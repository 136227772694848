import "../style.scss";
import { Card, Col, Collapse, Row, Space, Tag, Image, Button } from "antd";
import {
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { HistoryInfo } from "../Model/HistoryInfo";
import { FormatDate } from "../../../utils/GetDateNow";
import React from "react";
import ReportIssue from "./ReportIssue";
import { Issue } from "../Model/ReportResponse";

interface Props {
  dataSource: HistoryInfo;
  dataIndex: number;
  isFromReportPage: boolean;
  taskId: string;
}

const CardReportDetailList = ({ dataSource, dataIndex, isFromReportPage, taskId }: Props) => {
  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(true);

  const ImageDetail = (urlList: string[]) => {
    return (
      <Row gutter={[16, 16]}>
        {urlList?.map((item, index) => (
          <Col key={index}>
            <div className="image-detail">
              <Image
                src={item}
                preview={{
                  toolbarRender: (
                    _,
                    {
                      transform: { scale },
                      actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                    },
                  ) => (
                    <Space size={12} className="toolbar-wrapper">
                      <SwapOutlined rotate={90} onClick={onFlipY} />
                      <SwapOutlined onClick={onFlipX} />
                      <RotateLeftOutlined onClick={onRotateLeft} />
                      <RotateRightOutlined onClick={onRotateRight} />
                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                    </Space>
                  ),
                }}
              />
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  const renderStatus = (status: string) => {
    if (status === "exception") {
      return <Tag color={"red"}>Exception</Tag>;
    }
    if (status === "in-transit") {
      return <Tag color={"blue"}>In-Transit</Tag>;
    }
    if (status === "waiting") {
      return <Tag color={"warning"}>Waiting</Tag>;
    }

    if (status === "canceled") {
      return <Tag color={"red"}>Canceled</Tag>;
    }

    return <Tag color={"green"}>Success</Tag>;
  };

  const renderUpdateBy = (status: string, userEmail: string) => {
    if (userEmail.length > 0) {
      return <div className="text-label">{userEmail}, (Control Tower)</div>;
    }

    if (status === "exception" || status === "success") {
      return <div className="text-label">Driver</div>;
    }

    return <div className="text-label">-</div>;
  };

  const renderButtonShowDetail = () => {
    return (
      <>
        {isOpenDetail && (
          <Button
            type="default"
            size="large"
            onClick={() => {
              setIsOpenDetail(false);
            }}
            data-testid={`report-detail-close-button`}
          >
            ปิด
          </Button>
        )}
        {!isOpenDetail && (
          <Button
            size="large"
            type="primary"
            onClick={() => {
              setIsOpenDetail(true);
            }}
            data-testid={`report-detail-expand-button`}
          >
            ดูรายละเอียด
          </Button>
        )}
      </>
    );
  };

  const renderReportIssue = (status: string, userEmail: string, dataSource: Issue[], issue: Issue) => {
    if (userEmail.length > 0) {
      return <></>;
    }
    if (status === "exception" || status === "success") {
      return (
        isFromReportPage && (
          <Col xs={24} md={12} xxl={10}>
            <ReportIssue defaultValue={dataSource} issue={issue} taskId={taskId} />
          </Col>
        )
      );
    }
    return <></>;
  };

  return (
    <Card style={{ borderRadius: "16px" }} data-testid={`report-detail-history`}>
      <Row gutter={[16, 16]}>
        <Col span={24} style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>รายละเอียดรายงาน ({FormatDate(dataSource.timestamp)})</h2>
          {(dataSource.status === "success" || dataSource.status === "exception") && renderButtonShowDetail()}
        </Col>
        <Col xs={24} md={12} xxl={14}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div style={{ display: "flex" }}>
                <div className="title-label-history">Status</div>
                {renderStatus(dataSource.status)}
              </div>
            </Col>
            <Col span={24} style={{ display: "flex" }}>
              <div style={{ display: "flex" }}>
                <div className="title-label-history">Update By</div>
                {renderUpdateBy(dataSource.status, dataSource.userEmail)}
              </div>
            </Col>
            <Col span={24} style={{ display: "flex" }}>
              <div style={{ display: "flex" }}>
                <div className="title-label-history">
                  {dataSource.status === "exception" ? "เหตุผลที่ไม่สามารถส่งได้" : "หมายเหตุ"}
                </div>
                <div className="text-label">
                  {dataSource.userEmail.length > 0 && (
                    <>
                      {dataSource.note.length > 0
                        ? `${dataSource.reasonDescription}, ${dataSource.note}`
                        : dataSource.reasonDescription
                        ? dataSource.reasonDescription
                        : "-"}
                    </>
                  )}
                  {dataSource.userEmail.length === 0 && (
                    <>{dataSource.note.length > 0 ? dataSource.note : "-"}</>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>

        {dataIndex === 0 &&
          renderReportIssue(
            dataSource.status,
            dataSource.userEmail,
            dataSource.issue_history,
            dataSource.issue,
          )}

        {isOpenDetail &&
          (dataSource.status === "success" || dataSource.status === "exception") &&
          dataSource?.imageFolders?.map((item, index) => (
            <React.Fragment key={index}>
              <Col span={24}>
                <Collapse
                  size="large"
                  defaultActiveKey={item.urlList.length > 0 ? [1] : []}
                  collapsible={item.urlList.length > 0 ? "icon" : "disabled"}
                  ghost
                  items={[
                    {
                      key: "1",
                      label: (
                        <>
                          <div className="collapse-header">
                            <div>{item.type}</div>
                            {item.urlList.length > 0 && (
                              <div className="text-have-image">อัพโหลด {item.urlList.length || 0} รูปภาพ</div>
                            )}
                            {item.urlList.length === 0 && <div className="text-no-image">ไม่มีรูปภาพ</div>}
                          </div>
                        </>
                      ),
                      children: ImageDetail(item?.urlList || []),
                      showArrow: item.urlList.length > 0,
                    },
                  ]}
                  expandIconPosition="end"
                />
              </Col>
            </React.Fragment>
          ))}
      </Row>
    </Card>
  );
};

export default CardReportDetailList;
