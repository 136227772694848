import { Button, Form, Modal, Radio, Select } from "antd";
import { DOStoreListUpdate } from "../Driver";
import TextArea from "antd/es/input/TextArea";
import { Status } from "../GpsTracking";

type ModalFormProps = {
  updateStoreDetail: DOStoreListUpdate;
  open: boolean;
  onCancel: () => void;
  onSubmit: (values: any) => void;
  onChangeReason: (value: any) => void;
  onChangeStatus: (value: Status) => void;
  onSubmitConfirm: () => void;
  statusValue: Status | null;
  reasonValue: string;
  otherValue: string;

  openConfirm: boolean;
  loadingUpdateStatus: boolean;
};

const ModalChangeStatus: React.FC<ModalFormProps> = ({
  updateStoreDetail,
  open,
  onCancel,
  onSubmit,
  onChangeReason,
  onChangeStatus,
  onSubmitConfirm,
  statusValue,
  reasonValue,
  otherValue,

  openConfirm,
  loadingUpdateStatus,
}) => {
  const arrStatus: Status[] = [Status.SUCCESS, Status.IN_TRANSIT, Status.WAITING, Status.CANCELED];
  const [form] = Form.useForm();
  const statusReasonMapping: Record<string, Record<string, string>> = {
    [Status.SUCCESS]: {
      not_close_job: "พขร. ไม่ได้กดปิดงาน",
      internet_issue: "พขร. ติดปัญหาการใช้งานอินเตอร์เน็ต",
      application_issue: "พขร. ติดปัญหาการใช้งานแอปพลิเคชั่น",
    },
    [Status.CANCELED]: {
      accident: "เกิดอุบัติเหตุ",
      store_closed: "ร้านค้าไม่เปิดทำการ",
      suspend_delivery: "ยุติการจัดส่งขณะดำเนินงาน",
      car_malfunction: "รถขัดข้อง",
    },
    common: {
      close_wrong_job: "พขร. กดปิดงานผิดร้าน",
      application_issue: "พขร. ติดปัญหาการใช้งานแอปพลิเคชั่น",
      officer_close_job: "เจ้าหน้าที่ กดปิดงานผิดร้าน",
    },
  };

  const handleOk = () => {
    form.validateFields().then((value) => {
      onSubmit(value);
      form.resetFields();
    });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const renderRadioGroup = (statusValue: Status) => {
    const reasonMappingKey =
      statusValue === Status.IN_TRANSIT || statusValue === Status.WAITING ? "common" : statusValue;
    const mappingReasons = statusReasonMapping[reasonMappingKey];
    if (!mappingReasons) {
      return null;
    }
    return (
      <Radio.Group onChange={onChangeReason}>
        ({...Object.entries(mappingReasons).map(([key, value]) => <Radio value={key}>{value}</Radio>)})
        <Radio value={"other"}>
          {"อื่นๆ (กรุณาระบุเหตุผล)"}
          {reasonValue === "other" ? (
            <Form.Item
              name="note"
              rules={[
                {
                  required: reasonValue === "other" ? true : false,
                  message: "กรุณาระบุเหตุผล",
                },
              ]}
            >
              <TextArea rows={4} maxLength={256} showCount />
            </Form.Item>
          ) : null}
        </Radio>
      </Radio.Group>
    );
  };

  const renderMapReason = (statusValue: Status | null, reasonValue: string, otherReason: string) => {
    const reasonMappingKey =
      statusValue === Status.IN_TRANSIT || statusValue === Status.WAITING ? "common" : statusValue;
    if (!reasonMappingKey) {
      return null;
    }
    return (
      <span>
        {statusReasonMapping[reasonMappingKey][reasonValue]
          ? statusReasonMapping[reasonMappingKey][reasonValue]
          : `(อื่นๆ) ${otherReason}`}
      </span>
    );
  };
  return (
    <>
      <Modal forceRender title="อัปเดตสถานะ" open={open} onOk={handleOk} onCancel={handleCancel}>
        <div className="update-status-detail">
          <p>รายละเอียด</p>
          <p>
            Stop: <span>{updateStoreDetail.stop}</span>
          </p>
          <p>
            ชื่อสโตร์: <span>{updateStoreDetail.storeName}</span>
          </p>
          <p>
            หมายเลขสโตร์: <span>{updateStoreDetail.storeNumber}</span>
          </p>
          <p>
            สถานะเดิม: <span>{updateStoreDetail.currentStatus}</span>
          </p>
        </div>
        <Form
          form={form}
          name="change-status"
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item
            label="เปลี่ยนสถานะ"
            name="status"
            rules={[
              {
                required: true,
                message: "กรุณาเลือกสถานะ",
              },
            ]}
          >
            <Select placeholder="เลือกสถานะ" onChange={onChangeStatus}>
              {arrStatus
                .filter((s) => s.toUpperCase() !== updateStoreDetail.currentStatus.toUpperCase())
                .map((y, i) => (
                  <Select.Option value={y} key={i}>
                    <span>{y}</span>
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {statusValue !== null && (
            <Form.Item
              label="ระบุสาเหตุการเปลี่ยนสถานะ"
              name="reason"
              className="form-update-status-wrap-reason"
              rules={[
                {
                  required: true,
                  message: "กรุณาระบุสาเหตุการเปลี่ยนสถานะ",
                },
              ]}
            >
              {renderRadioGroup(statusValue)}
            </Form.Item>
          )}
        </Form>
      </Modal>
      <Modal
        forceRender
        className="modal-confirm-last-step"
        open={openConfirm}
        onCancel={onCancel}
        title={
          <p className="modal-title-last">
            ยืนยันการเปลี่ยนสถานะจาก <span>{updateStoreDetail.currentStatus}</span> เป็น{" "}
            <span> {statusValue}</span>
          </p>
        }
        footer={[
          <Button key="cencel" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loadingUpdateStatus} onClick={onSubmitConfirm}>
            OK
          </Button>,
        ]}
      >
        <div className="confirm-message-update-status">
          <p>
            Stop: <span>{updateStoreDetail.stop}</span>
          </p>
          <p>
            ชื่อสโตร์: <span>{updateStoreDetail.storeName}</span>
          </p>
          <p>
            หมายเลขสโตร์: <span>{updateStoreDetail.storeNumber}</span>
          </p>
          <p>สาเหตุการเปลี่ยนสถานะ: {renderMapReason(statusValue, reasonValue, otherValue)}</p>
        </div>
      </Modal>
    </>
  );
};

export default ModalChangeStatus;
